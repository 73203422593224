import React, {useEffect, useState} from 'react'
import {createRoot} from 'react-dom/client';
import {AdvancedMarker, APIProvider, InfoWindow, Map, Marker, Pin, useAdvancedMarkerRef, useMap} from '@vis.gl/react-google-maps';
import ControlPanel from './control-panel';
import {GreenField} from './Buildings';
import {PolygonF} from '@react-google-maps/api';


const greenFields: Array<GreenField> = [
  {
    NameOfSIP: 'DGtek Pty Ltd',
    ServiceArea: 'Anticipatory Notice',
    ServiceAreaIdentifier: 'DGtek_4079',
    Contract: 'DGtek Pty Ltd has entered into a real estate development project contract',
    NetworkInfrastructure: 'FTTP',
    ProjectArea: '88 Leveson St, North Melbourne, Victoria, 3051',
    EstimatedCompletionDate: '31/12/2024',
    lat: -37.80229649542481,
    lng: 144.95224544059752,
    show: false,
    coordinates: [[
      {lng: 144.9522762860012, lat: -37.802101520027094},
      {lng: 144.95148771655082, lat: -37.802016747954504},
      {lng: 144.95145553004264, lat: -37.80220854462975},
      {lng: 144.95224544059752, lat: -37.80229649542481}
    ]],
  },
  {
    NameOfSIP: 'DGtek Pty Ltd',
    ServiceArea: 'Anticipatory Notice',
    ServiceAreaIdentifier: 'DGtek_1357',
    Contract: 'DGtek Pty Ltd has entered into a real estate development project contract',
    NetworkInfrastructure: 'FTTP',
    ProjectArea: '590 Camberwell Rd, Camberwell, Victoria, 3124',
    EstimatedCompletionDate: '31/10/2024',
    lng: 145.07333239897306, lat: -37.84607391507482,
    show: false,
    coordinates: [[
      {lng: 145.07333239897306, lat: -37.84607391507482},
      {lng: 145.0731929241043, lat: -37.846823696802836},
      {lng: 145.073678403936, lat: -37.846880883231904},
      {lng: 145.0736998616081, lat: -37.84640644632999},
      {lng: 145.07333239897306, lat: -37.84607391507482},
    ]]
  },
  {
    NameOfSIP: 'DGtek Pty Ltd',
    ServiceArea: 'Anticipatory Notice',
    ServiceAreaIdentifier: 'DGtek_11016_1',
    Contract: 'DGtek Pty Ltd has entered into a real estate development project contract',
    NetworkInfrastructure: 'FTTP',
    ProjectArea: '2B Ballarat Road, Footscray, Victoria, 3011',
    EstimatedCompletionDate: '29/2/2024',
    lat: -37.79512832472259,
    lng: 144.90943193528622,
    show: false,
    coordinates: [[
      {lng: 144.9094091365096, lat: -37.79527192075336},
      {lng: 144.90983158442944, lat: -37.79531484054602},
      {lng: 144.90985505375832, lat: -37.7951707147238},
      {lng: 144.90943193528622, lat: -37.79512832472259}
    ]]
  },
  {
    NameOfSIP: 'DGtek Pty Ltd',
    ServiceArea: 'Anticipatory Notice',
    ServiceAreaIdentifier: 'DGtek_KP2020614',
    Contract: 'DGtek Pty Ltd has entered into a real estate development project contract',
    NetworkInfrastructure: 'FTTP',
    ProjectArea: '29-37 Genoa Street, Moorabbin, Victoria, 3189',
    EstimatedCompletionDate: '30/10/2022',
    lng: 145.05916043377337, lat: -37.94294668191291,
    show: false,
    coordinates: [[
      {lng: 145.0593367890161, lat: -37.94296624799912},
      {lng: 145.05916043377337, lat: -37.94294668191291},
      {lng: 145.05898541963515, lat: -37.94292341412795},
      {lng: 145.05895289785084, lat: -37.942899617522066},
      {lng: 145.05901219952193, lat: -37.94262470906866},
      {lng: 145.05940313148585, lat: -37.942673095673534},
      {lng: 145.0593367890161, lat: -37.94296624799912},
    ]]
  },

  {
    NameOfSIP: 'DGtek Pty Ltd',
    ServiceArea: 'Anticipatory Notice',
    ServiceAreaIdentifier: 'DGtek_9782',
    Contract: 'DGtek Pty Ltd has entered into a real estate development project contract ',
    NetworkInfrastructure: 'FTTP',
    ProjectArea: '13-15 & 22-28 Ford Street Footscray Victoria 3011',
    EstimatedCompletionDate: '31/7/2024',
    lng: 144.89894745985015, lat: -37.79799376185211,
    show: false,
    //       }, ((}

    coordinates: [[
      {
        lng: 144.89903427817512, lat: -37.798270531644,
      },
      {
        lng: 144.89918951102186, lat: -37.79818972907379,
      },
      {
        lng: 144.89929445244957, lat: -37.798059649996375,
      },
      {
        lng: 144.89931255736042, lat: -37.79793619387806,
      },
      {
        lng: 144.89927902974773, lat: -37.797885327777884,
      },
      {
        lng: 144.8991315082519, lat: -37.797922417646056,
      },
      {
        lng: 144.89894745985015, lat: -37.79799376185211,
      },
      {
        lng: 144.89885157087787, lat: -37.79804356816912,
      },
      {
        lng: 144.89903427817512, lat: -37.798270531644,
      },
    ],
    [
      {lng: 144.89905397771986, lat: -37.798293373249926},
      {lng: 144.8991968053499, lat: -37.79821919386746},
      {lng: 144.89934934388683, lat: -37.79823720886719},
      {lng: 144.89932252179668, lat: -37.798393515298095},
      {lng: 144.8992031634955, lat: -37.79838980633576},
      {lng: 144.899154213181, lat: -37.79841153025531},
      {lng: 144.89905397771986, lat: -37.798293373249926},
    ]]
  },
]

const Polygon = () => {
  const map = useMap();

  useEffect(() => {
    console.log("MAP", map)
    if (!map) return;

    greenFields.forEach((bld) => {
      bld.coordinates.forEach((cord) => {
      const polygon = new google.maps.Polygon({
        paths: cord,
        strokeColor: "#b7312c",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#b7312c",
        fillOpacity: 0.35
      });
        polygon.setMap(map);
      })
    })

  }, [map]);
  return <div />
}

const MyMap = () => {
  const [infowindowOpen, setInfowindowOpen] = useState(true);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [markerRef2, marker2] = useAdvancedMarkerRef();



  return (
    <APIProvider apiKey={"AIzaSyAtqvweVLEdaXwxfQkmp5Yotm13rFoVYhc"}>
      <Map
        zoom={14}
        center={{lng: 144.9094, lat: -37.7953}}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        mapId={"e704b3c0119dc847"}
      >
        {greenFields.map((bld, idx) =>
          <AdvancedMarker
            key={idx}
            position={{lat: bld.lat, lng: bld.lng}}
            ref={markerRef}
            onClick={() => setInfowindowOpen(true)}
          >
            {infowindowOpen && (
              <InfoWindow
                key={idx}
                position={{lat: bld.lat, lng: bld.lng}}
                maxWidth={400}
                onCloseClick={() => setInfowindowOpen(false)}>
                <h4>Project_Area: {bld.ProjectArea}</h4>
                <p>Name_Of_SIP: {bld.NameOfSIP}</p>
                <p>Contract: <b>{bld.Contract}</b></p>
                <p>Estimated_Completion_Date: <b>{bld.EstimatedCompletionDate}</b></p>
                <p>Service_Area_Identifier: <b>{bld.ServiceAreaIdentifier}</b></p>
                <p>Network_Infrastructure: {bld.NetworkInfrastructure}</p>
              </InfoWindow>
            )}
            <Pin />
          </AdvancedMarker>)}
          <Polygon />
      </Map>
      <ControlPanel />
    </APIProvider>
  )
};
export default MyMap;
